import MenuIcon from "@mui/icons-material/Menu";
import { Link, Slide, useScrollTrigger } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { NavLink as RouterLink, useLocation } from "react-router-dom";

import { DarkMode, LightMode, NavigateBefore } from "@mui/icons-material";
import { useState } from "react";
import CustomButton from "../helpers/CustomButton";
import LinkButton from "../helpers/LinkButton";
import "./Nav.css";

import useDarkreaderActive from "../helpers/useDarkreaderActive";
import trackEvent from "../services/analyticsService";
import useDarkMode from "../helpers/useDarkMode";
import useUIState from "../helpers/useUIState";

const drawerWidth = 240;

const navItems: NavItem[] = [
  {
    text: "Blog",
    to: "/"
  },
  {
    text: "Poetry",
    to: "/poetry"
  },
  {
    text: "Projects",
    to: "/projects"
  },
  // {
  //     text: "Write Post",
  //     to: "/blog/create"
  // },
  {
    text: "About",
    to: "/about"
  }
];

interface NavItem {
  text: string;
  to: string;
}

interface HideOnScrollProps {
  children: React.ReactElement;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function HideOnScroll({ children }: HideOnScrollProps) {
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function Nav() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        mt: 6
      }}
    >
      {/* <Typography variant="h6" sx={{ my: 2, marginBottom: { xs: 1, sm: 2 } }}>
        Menu
      </Typography>
      <Divider /> */}
      {navItems.map((item) => (
        <LinkButton to={item.to} key={item.to} size="large" chevron={false}>
          {item.text}
        </LinkButton>
      ))}
    </Box>
  );

  const darkMode = useDarkMode();

  // true if the Dark Reader extension is active, to disable mode select
  const darkReaderActive = useDarkreaderActive();

  const location = useLocation();

  const poemPathRegex = /\/poetry\/.+/;
  const blogPathRegex = /\/blog\/.+/;

  const onPoem = poemPathRegex.test(location.pathname);
  const onBlog = blogPathRegex.test(location.pathname);

  const showNavigateBefore = onPoem || onBlog;
  const backTo = onPoem
    ? { link: "/poetry", title: "Poetry" }
    : { link: "/", title: "Blog" };

  return (
    <Box sx={{ display: "flex" }}>
      {/* <HideOnScroll> */}
      <AppBar elevation={0} component="nav" sx={{ backgroundColor: "inherit" }}>
        <Toolbar>
          <Link
            sx={{
              display: {
                xs: showNavigateBefore ? "none" : "block",
                md: "block"
              },
              color: darkMode ? "white" : "black",
              textDecoration: "none",
              "&.active > button": {
                textDecoration: "none"
              }
            }}
            component={RouterLink}
            to="/"
          >
            <Typography
              variant="h4"
              component="div"
              sx={{ color: "text.primary" }}
            >
              Distant Land
            </Typography>
          </Link>

          <Link
            sx={{
              display: {
                xs: showNavigateBefore ? "block" : "none",
                md: "none"
              },
              color: darkMode ? "white" : "black",
              textDecoration: "none",
              "&.active > button": {
                textDecoration: "none"
              }
            }}
            component={RouterLink}
            to={backTo.link}
          >
            <CustomButton
              size="large"
              chevron={false}
              startIcon={showNavigateBefore && <NavigateBefore />}
              sx={{
                width: {
                  xs: "142.417px",
                  sm: "166.05px"
                }
              }}
            >
              {backTo.title}
            </CustomButton>
          </Link>

          {!darkReaderActive && (
            <CustomButton
              size="large"
              sx={{ ml: "auto", display: { xs: "flex", md: "none" } }}
              chevron={false}
              onClick={() => useUIState.getState().setDarkMode(!darkMode)}
              endIcon={
                darkMode ? <LightMode htmlColor="yellow" /> : <DarkMode />
              }
            >
              {darkMode ? "Light Mode" : "Dark Mode"}
            </CustomButton>
          )}

          <IconButton
            aria-label="open drawer"
            edge="end"
            onClick={() => {
              if (!mobileOpen) {
                trackEvent({ event: "Opened Menu", properties: {} });
              }

              setMobileOpen(!mobileOpen);
            }}
            sx={{ ml: "auto", display: { md: "none" } }}
          >
            <MenuIcon
              fontSize="large"
              htmlColor={darkMode ? "white" : "black"}
            />
          </IconButton>

          <Box
            sx={{
              marginLeft: "auto",
              display: { xs: "none", md: "flex" }
            }}
          >
            {navItems.map((item) => (
              <LinkButton
                to={item.to}
                key={item.to}
                size="large"
                chevron={false}
                sx={{ ml: 1 }}
              >
                {item.text}
              </LinkButton>
            ))}

            {!darkReaderActive && (
              <IconButton
                size="large"
                sx={{
                  transform: "translateY(-0.145em)",
                  display: { xs: "none", md: "flex" }
                }}
                onClick={() => useUIState.getState().setDarkMode(!darkMode)}
              >
                {darkMode ? (
                  <LightMode htmlColor="yellow" />
                ) : (
                  <DarkMode htmlColor="blue" />
                )}
              </IconButton>
            )}
          </Box>
        </Toolbar>
        <Divider variant="middle" sx={{ borderColor: "secondary.main" }} />
      </AppBar>
      {/* </HideOnScroll> */}
      <Toolbar aria-hidden />
      <Box component="nav">
        <Drawer
          anchor="right"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth
            }
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
