import { TextField, TextFieldProps } from "@mui/material";
import { useField, useFormikContext } from "formik";

type FormikFieldProps = TextFieldProps & {
  name: string;
  label: string;
  disableHelperText?: boolean;
};

export default function FormikField({
  name,
  disableHelperText = false,
  label,
  ...props
}: FormikFieldProps) {
  const { isSubmitting } = useFormikContext();
  const [field, meta] = useField(name);

  return (
    <TextField
      disabled={props.disabled ?? isSubmitting}
      {...props}
      fullWidth
      label={label}
      name={name}
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      error={meta.touched && meta.error !== undefined}
      helperText={
        !disableHelperText &&
        meta.touched &&
        meta.error !== undefined &&
        meta.error
      }
      // sx={{
      //     "& .MuiOutlinedInput-root": {
      //         color: "red"
      //     }
      // }}
      //variant="standard"
      InputLabelProps={{ shrink: true }}
      // inputProps={{
      //     sx: {
      //         "&::placeholder": {
      //         color: "green"
      //         }
      //     }
      // }}
    />
  );
}
