import { Box } from "@mui/material";
import { useEffect, useRef } from "react";

declare global {
  interface Window {
    /**
     * Stub that has the only aspect of the commento script object that I'm aware of
     */
    commento?: {
      reInit: ({ pageId }: { pageId: string }) => void;
      main: () => void;
    };
  }
}

/**
 * Comments are keyed on a resource. It's similar to a url path,
 * but uses ids instead of url slugs
 *
 * TODO: consider using slug, so that it's easy to see comments on moderation panel
 */
export default function CommentsSection({ resource }: { resource: string }) {
  const boxId = "commento-box"; // + btoa(resource)

  const alreadyKickedOffRef = useRef(false);

  useEffect(() => {
    if (!alreadyKickedOffRef.current) {
      alreadyKickedOffRef.current = true;

      if (!window.commento) {
        // init empty object so commento.js script extends this with global functions
        window.commento = {
          reInit({ pageId }) {
            console.error("attempt call to reInit before load by ", pageId);
          },
          main() {
            console.error("call to main function before load");
          }
        };
        const script = document.createElement("script");
        // Replace this with the url to your commento instance's commento.js script
        script.src = `https://comments.distant.land/js/commento.js`;
        script.defer = true;
        // Set default attributes for first load
        script.setAttribute("data-auto-init", "false");
        script.setAttribute("data-page-id", resource);
        script.setAttribute("data-id-root", boxId);
        script.onload = () => {
          if (window.commento) {
            // Tell commento.js to load the widget
            window.commento.main();
          } else {
            console.error(
              "Cannot call main after onload, window.commento is undefined"
            );
          }
        };
        document.getElementsByTagName("head")[0].appendChild(script);
      } else {
        // In-case the commento.js script has already been loaded reInit the widget with a new pageId
        window.commento.reInit({
          pageId: resource
        });
      }
    }
  }, []);

  return (
    <Box
      sx={{
        minHeight: "370px"
        // changing this messes up commento styles
        // "& #commento-textarea-root": {
        //   color: "text.primary",
        //   backgroundColor: "background.paper"
        // }
      }}
      id={boxId}
    />
  );
}
