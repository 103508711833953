import {
  Badge,
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography
} from "@mui/material";
import { useEffect } from "react";
import LinkButton from "../helpers/LinkButton";
import ConwayProject from "./conway/ConwayProject";
import DevelopProject from "./develop/DevelopProject";
import MandelbotProject from "./mandelbrot/MandelbrotProject";

export default function ProjectsOverview() {
  useEffect(() => {
    document.title = "Projects Overview";
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          rowGap: { xs: 3, sm: 6 },
          columnGap: { xs: 3, sm: 6 },
          p: { xs: 3, sm: 6 }
        }}
      >
        <Card
          sx={{ maxWidth: 545, border: 1, borderColor: "secondary.main" }}
          elevation={1}
        >
          <CardMedia component={ConwayProject} isPreview={true} />
          <CardContent>
            <Typography paragraph variant="h5">
              Multicolor Game of Life
            </Typography>
            <Typography variant="body2">
              Conway's Game of Life implementation with additional logic related
              to color propagation. Cells are assigned colors at the start of
              the simulation, and upon state transition from dead to alive,
              cells will randomly assume one of the surrounding colors.
            </Typography>
            <CardActions>
              <LinkButton
                LinkProps={{ sx: { ml: "auto" } }}
                to="/projects/conway"
              >
                Go to Project
              </LinkButton>
            </CardActions>
          </CardContent>
        </Card>

        <Card
          sx={{ maxWidth: 545, border: 1, borderColor: "secondary.main" }}
          elevation={1}
        >
          <CardMedia component={MandelbotProject} isPreview={true} />
          <CardContent>
            <Typography paragraph variant="h5">
              Mandelbrot Set Viewer (Click to Zoom)
            </Typography>
            <Typography variant="body2">
              The Mandelbrot Set is a fractal. It's generated by iterating a
              simple function on the points of the complex plane, and seeing if
              that point diverges to infinity or not.
            </Typography>
            <CardActions>
              <LinkButton
                LinkProps={{ sx: { ml: "auto" } }}
                to="/projects/mandelbrot"
              >
                Go to Project
              </LinkButton>
            </CardActions>
          </CardContent>
        </Card>

        <Card
          sx={{ maxWidth: 545, border: 1, borderColor: "secondary.main" }}
          elevation={1}
        >
          <CardMedia component={DevelopProject} isPreview={true} />
          <CardContent sx={{ borderTop: 1, borderColor: "secondary.main" }}>
            <Badge badgeContent="Hiatus" color="error">
              <Typography paragraph variant="h5">
                Develop - Track Anything{" "}
              </Typography>
            </Badge>

            <Typography variant="body2">
              <em>Develop</em> is a system for tracking projects, focused mainly
              on my personal development projects. It has a Kanban board to
              track todo items and assign them status and priority.
            </Typography>
            <CardActions>
              <LinkButton
                LinkProps={{ sx: { ml: "auto" } }}
                to="/projects/develop"
              >
                Go to Project
              </LinkButton>
            </CardActions>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
