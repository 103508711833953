declare global {
  interface Array<T> {
    chooseRandom(): T;
  }
}

if (!Array.prototype.chooseRandom) {
  Array.prototype.chooseRandom = function <T>(this: T[]): T {
    return this[Math.floor(Math.random() * this.length)];
  };
}

class RGBA {
  r: number;
  g: number;
  b: number;
  a: number;

  constructor(r: number, g: number, b: number, a: number) {
    this.r = r;
    this.g = g;
    this.b = b;
    this.a = a;
  }

  equalTo(other: RGBA) {
    return (
      this.r === other.r &&
      this.g === other.g &&
      this.b === other.b &&
      this.a === other.a
    );
  }

  // creates a random RGBA value. If opacity is true, opacity will be randomized as well-- otherwise it will be 1.
  static random(opacity: boolean): RGBA {
    const r = Math.round(Math.random() * 255);
    const g = Math.round(Math.random() * 255);
    const b = Math.round(Math.random() * 255);
    const a = opacity ? Math.random() : 1;

    return new RGBA(r, g, b, a);
  }

  static randomDark(boundingDarkness: number) {
    const r = Math.round(Math.random() * boundingDarkness);
    const g = Math.round(Math.random() * boundingDarkness);
    const b = Math.round(Math.random() * boundingDarkness);

    return new RGBA(r, g, b, 1);
  }

  static randomBlueGreen(): RGBA {
    const r = Math.round(Math.random() * 40);
    const g = Math.round(Math.random() * 200);
    const b = 100 + Math.round(Math.random() * 100);

    return new RGBA(r, g, b, 1);
  }

  static randomGreen(): RGBA {
    const r = Math.round(Math.random() * 10);
    const g = 100 + Math.round(Math.random() * 100);
    const b = Math.round(Math.random() * 200);

    return new RGBA(r, g, b, 1);
  }

  static black(): RGBA {
    return new RGBA(0, 0, 0, 1);
  }

  static white(): RGBA {
    return new RGBA(255, 255, 255, 1);
  }

  static blue(): RGBA {
    return new RGBA(51, 67, 233, 1);
  }

  static pink(): RGBA {
    return new RGBA(255, 0, 255, 1);
  }

  // Turns an RGBA color into a string that can be used to configure colors on an HTML5 canvas.
  fillStylePattern(): string {
    return (
      "rgba(" + this.r + ", " + this.g + ", " + this.b + ", " + this.a + ")"
    );
  }

  static copy(toCopy: RGBA) {
    return new RGBA(toCopy.r, toCopy.g, toCopy.b, toCopy.a);
  }

  // Blend together an array of rgba objects
  static blend(rgba_array: RGBA[]) {
    const l = rgba_array.length;
    let r_sum = 0;
    let g_sum = 0;
    let b_sum = 0;
    let a_sum = 0;

    for (let i = 0; i < l; i++) {
      r_sum += rgba_array[i].r;
      g_sum += rgba_array[i].g;
      b_sum += rgba_array[i].b;
      a_sum += rgba_array[i].a;
    }

    return new RGBA(
      Math.round(r_sum / l),
      Math.round(g_sum / l),
      Math.round(b_sum / l),
      a_sum / l
    );
  }

  static rollingBlend(blendedColor: RGBA, newColor: RGBA, sampleSize: number) {
    blendedColor.r -= blendedColor.r / sampleSize;
    blendedColor.r += newColor.r / sampleSize;
    blendedColor.r = Math.ceil(blendedColor.r);

    blendedColor.g -= blendedColor.g / sampleSize;
    blendedColor.g += newColor.g / sampleSize;
    blendedColor.g = Math.ceil(blendedColor.g);

    blendedColor.b -= blendedColor.b / sampleSize;
    blendedColor.b += newColor.b / sampleSize;
    blendedColor.b = Math.ceil(blendedColor.b);

    blendedColor.a -= blendedColor.a / sampleSize;
    blendedColor.a += newColor.a / sampleSize;

    return blendedColor;
  }
}

interface Cell {
  alive: boolean;
  rgba: RGBA;
  color_average: RGBA;
  age: number;
}

// representation of a toroidal game of life universe
export default class Universe {
  width: number;
  height: number;
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  cellSize: number;
  uni: Cell[][];
  generation: number;
  color_average_depth = 11;
  stale = true;

  constructor(
    width: number,
    height: number,
    canvas: HTMLCanvasElement,
    cellSize: number
  ) {
    this.width = width;
    this.height = height;
    this.canvas = canvas;
    this.ctx = this.canvas.getContext("2d")!; // TODO: error instead of assert

    canvas.height = cellSize * height;
    canvas.width = cellSize * width;
    this.cellSize = cellSize;

    const uni: Cell[][] = new Array(width);

    for (let i = 0; i < width; i++) {
      uni[i] = new Array(height);
    }

    this.uni = uni;

    this.randomlySeed();
    this.generation = 1;
  }

  // returns an array of all the neighboring cells around a given cell, taking the toroidal shape into account
  neighboringCells(x: number, y: number): Cell[] {
    const uni = this.uni;
    const width = this.width;
    const height = this.height;

    const above = y === 0 ? height - 1 : y - 1;
    const below = y === height - 1 ? 0 : y + 1;
    const leftOf = x === 0 ? width - 1 : x - 1;
    const rightOf = x === width - 1 ? 0 : x + 1;

    return [
      uni[leftOf][above],
      uni[leftOf][y],
      uni[leftOf][below],
      uni[x][above],
      uni[x][below],
      uni[rightOf][above],
      uni[rightOf][y],
      uni[rightOf][below]
    ];
  }

  tick(): boolean {
    // if no color updates are made, we consider it stale
    let stale = true;

    this.generation++;
    const uni = this.uni;
    const width = this.width;
    const height = this.height;
    let x;

    const newUni: Cell[][] = new Array(width);

    for (x = 0; x < width; x++) {
      newUni[x] = new Array(height);
    }

    for (x = 0; x < width; x++) {
      for (let y = 0; y < height; y++) {
        const neighbors = this.neighboringCells(x, y);
        const liveNeighboringCells = neighbors.filter((cell) => cell.alive);
        const colors = liveNeighboringCells.map((cell) => cell.rgba);

        const currentCell = uni[x][y];

        // b3/s23
        if (currentCell.alive) {
          // stay alive if 2 or 3 neighbors
          if (
            liveNeighboringCells.length === 2 ||
            liveNeighboringCells.length === 3
          ) {
            newUni[x][y] = { ...currentCell, age: currentCell.age + 1 };
            // our little cells are growing up :')
          } else {
            newUni[x][y] = {
              ...currentCell,
              age: currentCell.age + 1,
              alive: false
            };
          }
        } else {
          // come to life if exactly three neighbors are alive
          // when it comes to life, it randomly assumes the color of one of those neighbors

          if (liveNeighboringCells.length === 3) {
            newUni[x][y] = {
              ...currentCell,
              age: currentCell.age + 1,
              alive: true,
              rgba: colors.chooseRandom()
            };
          } else {
            //cell still dead.. increment age? *shrugs*
            newUni[x][y] = { ...currentCell, age: currentCell.age + 1 };
          }
        }

        newUni[x][y].color_average = RGBA.rollingBlend(
          RGBA.copy(uni[x][y].color_average),
          newUni[x][y].rgba,
          this.color_average_depth
        );

        // if(newUni[x][y].alive !== currentCell.alive) {
        //     stale = false
        // }

        if (!newUni[x][y].color_average.equalTo(currentCell.color_average)) {
          // at least one color changes, so it's not stale
          stale = false;
        }
      }
    }

    // swap out the buffer
    this.uni = newUni;
    return stale;
  }

  render() {
    // variables for counters
    let x;
    let y;

    // clear canvas
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // render cells TODO: have state for this I can change
    const displayColors = true; // document.getElementById("display_colors").checked
    let alive = 0;
    for (x = 0; x < this.width; x++) {
      for (y = 0; y < this.height; y++) {
        //var colors = this.uni[x][y].neighbors.map(function(cell){return cell.color_average})
        //var blendedAverage = blend(colors)

        if (this.uni[x][y].alive) {
          alive++;
          if (displayColors) {
            this.ctx.fillStyle =
              this.uni[x][y].color_average.fillStylePattern();
          } else {
            this.ctx.fillStyle = RGBA.black().fillStylePattern();
          }
        } else {
          if (displayColors) {
            this.ctx.fillStyle =
              this.uni[x][y].color_average.fillStylePattern();
          } else {
            this.ctx.fillStyle = RGBA.white().fillStylePattern();
          }
        }

        this.ctx.fillRect(
          x * this.cellSize,
          y * this.cellSize,
          this.cellSize + 1,
          this.cellSize + 1
        );
        this.ctx.stroke();
      }
    }

    // TODO: have grid state I can change
    const renderGrid = true; // document.getElementById("display_grid").checked

    if (renderGrid) {
      // render grid
      this.ctx.lineWidth = 1;
      for (x = 0; x < this.width; x++) {
        this.ctx.beginPath();
        this.ctx.moveTo(x * this.cellSize, 0);
        this.ctx.lineTo(x * this.cellSize, this.height * this.cellSize);
        this.ctx.stroke();
      }

      for (y = 0; y < this.height; y++) {
        this.ctx.beginPath();
        this.ctx.moveTo(0, y * this.cellSize);
        this.ctx.lineTo(this.width * this.cellSize, y * this.cellSize);
        this.ctx.stroke();
      }

      this.ctx.beginPath();
    }
  }

  randomlySeed() {
    let x;
    let y;

    const numRandomColors = 5;

    const randomColors = [...new Array(numRandomColors)].map(function () {
      return RGBA.random(false);
    });

    for (x = 0; x < this.width; x++) {
      for (y = 0; y < this.height; y++) {
        //var color = blue()
        //var color = [black(), blue(), pink()].chooseRandom()
        //var color = randomColors.chooseRandom();

        const color = RGBA.random(false);

        this.uni[x][y] = {
          age: 0,
          alive: Math.random() >= 0.7,
          // legacy code copies. I believe it's needed because of later mutations
          rgba: RGBA.copy(color),
          color_average: RGBA.copy(color)
        };
      }
    }
  }

  mainLoop(cancellationObject: { cancellationFlag: boolean }) {
    if (cancellationObject.cancellationFlag === true) {
      return;
    }

    // todo; Optimize to do tick while waiting, and maybe to tick while rendering
    const stale = this.tick();
    this.render();

    if (stale) {
      // reseed
      this.generation = 1;
      this.randomlySeed();
    }

    window.requestAnimationFrame(this.mainLoop.bind(this, cancellationObject));
  }

  run() {
    const cancellationObject = { cancellationFlag: false };
    window.requestAnimationFrame(this.mainLoop.bind(this, cancellationObject));
    return cancellationObject;
  }
}
