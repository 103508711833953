import { ArrowForwardIos } from "@mui/icons-material";
import { Button, ButtonProps, Paper } from "@mui/material";
import { TouchRippleActions } from "@mui/material/ButtonBase/TouchRipple";
import omit from "lodash.omit";
import { useRef } from "react";

export interface CustomButtonProps extends ButtonProps {
  chevron?: boolean;
}

export default function CustomButton(props: CustomButtonProps) {
  const touchRippleRef = useRef<TouchRippleActions>(null);

  const showChevron = props.chevron === undefined || props.chevron;

  return (
    <Button
      {...omit(props, "chevron", "children")}
      sx={{
        "&:hover": {
          backgroundColor: "inherit"
        },
        ...props.sx
      }}
      touchRippleRef={touchRippleRef}
      onMouseDown={() => touchRippleRef.current?.stop()}
      onMouseEnter={() => touchRippleRef.current?.pulsate()}
      onMouseLeave={() => touchRippleRef.current?.stop()}
      endIcon={props.endIcon || (showChevron ? <ArrowForwardIos /> : null)}
    >
      <Paper
        sx={{ backgroundImage: "unset", backgroundColor: "unset" }}
        elevation={0}
      >
        {props.children}
      </Paper>
    </Button>
  );
}
