import { Box } from "@mui/material";
import { useRef, useEffect } from "react";
import trackEvent from "../../services/analyticsService";
import Universe from "./conway";

interface ConwayProjectProps {
  isPreview?: boolean;
}

export default function ConwayProject(props: ConwayProjectProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const cancellationObject = useRef<{ cancellationFlag: boolean } | null>(null);
  const universeRef = useRef<Universe | null>(null);

  useEffect(() => {
    if (!props.isPreview) {
      document.title = "Game of Life";
    }

    // constructing the universe causes "myCanvas" to be resized
    // TODO: check for null ref
    universeRef.current = new Universe(49, 28, canvasRef.current!, 20);
    universeRef.current.render();
    cancellationObject.current = universeRef.current.run();

    return function cleanup() {
      // FIXME: this can't do anything, as JS is single threaded and the flag is erased
      // before it's consumed. not sure what I was thinking when I wrote this, but there
      // may be a memory leak here
      if (cancellationObject.current) {
        cancellationObject.current.cancellationFlag = true;
      }
      cancellationObject.current = null;
    };
  }, []);

  function recreateUniverse() {
    trackEvent({
      event: "Conway Engagement",
      properties: {
        action: "reseed",
        page: props.isPreview ? "projects overview" : "conway page"
      }
    });

    cancellationObject.current!.cancellationFlag = true;

    universeRef.current = new Universe(49, 28, canvasRef.current!, 20);
    universeRef.current.render();
    cancellationObject.current = universeRef.current.run();
  }

  return (
    <Box sx={{ width: "100%", "&:hover": { cursor: "pointer" } }}>
      <canvas
        style={{ width: "100%", display: "block" }}
        onClick={recreateUniverse}
        ref={canvasRef}
      >
        Your browser does not support the HTML5 canvas tag. Stop using Netscape.
      </canvas>
    </Box>
  );
}
