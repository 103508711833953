type PlausibleEvent =
  | {
      event: "Conway Engagement";
      properties: {
        action: "reseed";
        page: "projects overview" | "conway page";
      };
    }
  | {
      event: "Mandelbrot Engagement";
      properties:
        | {
            action: "reset";
          }
        | {
            action: "zoom";
            depth: number;
          };
    }
  | {
      event: "Opened Menu";
      properties: Record<string, never>;
    }
  | {
      event: "Clicked Poem Image";
      properties: {
        path: string;
        title?: string;
        imagePath?: string;
      };
    };

// this assumes the script was loaded
declare global {
  interface Window {
    plausible: (
      event: string,
      custom: { props: Record<string, string | number> }
    ) => void;
  }
}

//window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }

export default function trackEvent(event: PlausibleEvent) {
  window.plausible(event.event, { props: event.properties });
}
