import { useEffect, useMemo, useState } from "react";
import useDarkMode from "./useDarkMode";
import useUIState from "./useUIState";

const useDarkreaderActive = () => {
  const htmlElement = document.documentElement;

  const darkReaderAttrScheme = "data-darkreader-scheme";

  const isActive = () =>
    htmlElement.attributes.getNamedItem(darkReaderAttrScheme)?.value === "dark";

  const initialState = useMemo(() => isActive(), []);

  const [darkReaderActive, setDarkReaderPresent] = useState(initialState);

  const darkMode = useDarkMode();

  useEffect(() => {
    if (darkReaderActive && !darkMode) {
      useUIState.getState().setDarkMode(true);
    }
  }, [darkReaderActive]);

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      // TODO: leverage the mutation directly instead of just rechecking state
      mutationsList.forEach(() => {
        setDarkReaderPresent(isActive());
      });
    });

    observer.observe(htmlElement, {
      attributes: true,
      subtree: false
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return darkReaderActive;
};

export default useDarkreaderActive;
