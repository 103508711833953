import {
  createTheme,
  CssBaseline,
  GlobalStyles,
  Link,
  Paper,
  responsiveFontSizes,
  ThemeProvider,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useEffect } from "react";
import {
  Link as RouterLink,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import "./App.css";
import ViewPost from "./blog/ViewPost";
import Nav from "./components/Nav";

import { Box } from "@mui/system";
import texturedDark from "./assets/textured-dark.jpg";
import texturedWhite from "./assets/textured-white.jpg";

import Home from "./components/Home";
import ConwayProject from "./projects/conway/ConwayProject";
import MandelbotProject from "./projects/mandelbrot/MandelbrotProject";
import ProjectsOverview from "./projects/ProjectsOverview";

import BambooGreen from "./assets/bamboo-flipped-green.png";
import Bamboo from "./assets/bamboo-flipped.png";
import About from "./components/About";
import CustomButton from "./helpers/CustomButton";
import useDarkMode from "./helpers/useDarkMode";
import useLocationHistory from "./helpers/useLocationHistory";
import useUIState from "./helpers/useUIState";
import PoetryGallery from "./poetry/PoetryGallery";
import DevelopProject from "./projects/develop/DevelopProject";

function NotFound() {
  return (
    <Box
      display="flex"
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Paper
        sx={{
          m: 6,
          p: 3,
          borderRadius: 1,
          border: 1,
          borderColor: "secondary.main",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Typography
          textAlign="center"
          mb={3}
          variant="h5"
          whiteSpace="pre-line"
        >
          Not all those who wander are lost, but you are. Page not found.
        </Typography>

        <Link component={RouterLink} to="/">
          <CustomButton>Return Home</CustomButton>
        </Link>
      </Paper>
    </Box>
  );
}

function HistoryWatcher() {
  //const locationHistory = useAppSelector(state => state.locations.locationHistory);

  const location = useLocation();

  useEffect(() => {
    useLocationHistory.getState().setNewLocation(location.pathname);
  }, [location]);

  return null;
}

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/poetry/:slug?" element={<PoetryGallery />} />
      <Route path="/about" element={<About />} />
      {/* <Route path="/blog/create" element={<AddPost/>}/> */}
      <Route path="/projects" element={<ProjectsOverview />} />
      <Route path="/projects/conway" element={<ConwayProject />} />
      <Route path="/projects/mandelbrot" element={<MandelbotProject />} />
      <Route path="/projects/develop" element={<DevelopProject />} />

      <Route path="/blog/:urlSlug" element={<ViewPost />} />
      {/* <Route path="/blog/edit/:urlSlug" element={<AddPost edit/>}/> */}

      <Route path="/*" element={<NotFound />} />
      {/* </Routes> */}
    </Routes>
  );
}

declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    transparent: true;
  }
}

function App() {
  const darkMode = useDarkMode();

  const texturedBackgroundImage = useUIState(
    (state) => state.texturedBackgroundImage
  );

  const customTheme = responsiveFontSizes(
    createTheme({
      palette: {
        mode: darkMode ? "dark" : "light"
        //primary: darkMode ? white : black
      },
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              backgroundImage: texturedBackgroundImage
            }
          },
          variants: [
            {
              props: { variant: "transparent" },
              style: {
                backgroundImage: "unset",
                backgroundColor: "unset"
              }
            }
          ]
        }
        // MuiButtonBase: {
        //     styleOverrides: {
        //         root: {
        //             color: darkMode ? "white" : "black"
        //         }
        //     }
        // },
        // MuiButton: {
        //     styleOverrides: {
        //     root: {
        //         color: darkMode ? "white" : "black"
        //     }
        //     }
        // }
      }
    })
  );

  const smallScreen = useMediaQuery(customTheme.breakpoints.down("sm"));

  return (
    <ThemeProvider theme={customTheme}>
      <GlobalStyles
        styles={{
          "a:visited": { color: customTheme.palette.secondary.main },
          a: { color: customTheme.palette.primary.main },
          blockquote: {
            borderLeft: `4px solid ${customTheme.palette.secondary.main}`,
            marginLeft: customTheme.spacing(3.5),
            paddingLeft: customTheme.spacing(1.5)
          }
        }}
      />
      <CssBaseline />
      <div
        style={{
          minHeight: "100%",
          maxWidth: "100%",
          height: "1px", // so children can use 100% height
          backgroundImage: `url(${darkMode ? texturedDark : texturedWhite})`
        }}
      >
        <HistoryWatcher />

        <Nav />
        <Box
          id="content-wrapper"
          sx={{
            background: {
              xs: "transparent",
              sm: `url(${darkMode ? BambooGreen : Bamboo}) no-repeat right top`
            },
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            width: "100%",
            maxWidth: "100%",
            height: `calc(100% - ${smallScreen ? 56 : 64}px)`,
            minHeight: `calc(100% - ${smallScreen ? 56 : 64}px)`,
            maxHeight: `calc(100% - ${smallScreen ? 56 : 64}px)`,
            overflowY: "auto"
          }}
        >
          <AppRoutes />
        </Box>
      </div>
    </ThemeProvider>
  );
}

export default App;
