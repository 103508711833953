import GhostContentAPI, { PostOrPage } from "@tryghost/content-api";
import { create } from "zustand";

const api = new GhostContentAPI({
  url: "https://distant.land",
  key: "02f0a8dd390f021c5f7aea97e7",
  version: "v5.0"
});

interface Posts {
  [slug: string]: PostOrPage | undefined | null;
}

// do not export this. This is safe in this context, since JS is single threaded
const internalState = {
  fetching: new Set<string>(),
  fetched: new Set<string>(),
  fetchingPostList: false,
  fetchingPoemList: false
};

interface GhostState {
  posts: Posts;
  postList: PostOrPage[];
  poemList: PostOrPage[];
  requestPostList: () => Promise<void>;
  requestPoemList: () => Promise<void>;
  requestPost: (slug: string) => Promise<void>;
}

const useGhost = create<GhostState>((set) => ({
  posts: {},
  postList: [],
  poemList: [],
  requestPostList: async () => {
    if (internalState.fetchingPostList) {
      // nothing to do
      return;
    } else {
      // needs fetching
      // lock on this
      internalState.fetchingPostList = true;

      const result = await api.posts
        .browse({
          fields: ["slug", "excerpt", "published_at", "title", "featured"],
          filter: "tag:-hash-poem"
        })
        .catch((e) => {
          console.log(`Failed to list posts`, e);
          return null;
        });

      internalState.fetchingPostList = false;
      set(() => ({
        postList: result || []
      }));
    }
  },
  requestPoemList: async () => {
    if (internalState.fetchingPoemList) {
      // nothing to do
      return;
    } else {
      // needs fetching
      // lock on this
      internalState.fetchingPoemList = true;

      const result = await api.posts
        .browse({
          fields: [
            "slug",
            "id",
            "excerpt",
            "published_at",
            "title",
            "featured",
            "feature_image",
            "feature_image_alt"
          ],
          filter: "tag:hash-poem"
        })
        .catch((e) => {
          console.log(`Failed to list posts`, e);
          return null;
        });

      internalState.fetchingPostList = false;
      set(() => ({
        poemList: result || []
      }));
    }
  },
  requestPost: async (slug: string) => {
    if (internalState.fetched.has(slug) || internalState.fetching.has(slug)) {
      // nothing to do
      return;
    } else {
      // needs fetching
      // lock on this
      internalState.fetching.add(slug);

      const result = await api.posts
        .read({ slug: slug }, { include: "tags" })
        .catch((e) => {
          console.log(`Failed to get post slug=${slug}`, e);
          return null;
        });

      internalState.fetching.delete(slug);

      set(({ posts }) => ({
        posts: { ...posts, [slug]: result }
      }));
    }
  }
}));

export default useGhost;
