import { create } from "zustand";

import texturedDark from "../assets/textured-dark.jpg";
import texturedWhite from "../assets/textured-white.jpg";

interface UIState {
  darkMode: boolean;
  texturedBackgroundImage: string;
  setDarkMode: (darkMode: boolean) => void;
}

const prefersDarkMode = window.matchMedia(
  "(prefers-color-scheme: dark)"
).matches;

// TODO: prefer saved state
const initialDarkMode = prefersDarkMode;

const useUIState = create<UIState>((set) => ({
  darkMode: initialDarkMode,
  texturedBackgroundImage: `url(${
    initialDarkMode ? texturedDark : texturedWhite
  })`,
  setDarkMode: (darkMode: boolean) => {
    set(() => ({
      darkMode: darkMode,
      texturedBackgroundImage: `url(${darkMode ? texturedDark : texturedWhite})`
    }));
  }
}));

export default useUIState;
