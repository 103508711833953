import { Box, Paper, Typography } from "@mui/material";

import focusImage from "../assets/pink-bamboo-ai.jpg";

export default function About() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%"
      }}
    >
      <Paper
        sx={{
          border: 1,
          borderRadius: 1,
          borderColor: "secondary.main",
          m: 1,
          maxWidth: "min(100%, 400px)",
          overflow: "hidden"
        }}
      >
        <Typography sx={{ p: 2 }}>
          Hey, I'm Nathan, thanks for checking out my site. I'm a programmer,
          who (along with tech) loves poetry and literature. You can contact me
          at {["han@", "nat"].reverse().join("")} this domain if you
          want—otherwise, enjoy the site.
        </Typography>

        <img
          src={focusImage}
          style={{
            display: "block",
            width: "1024px",
            height: "auto",
            aspectRatio: "1/1",
            maxWidth: "100%"
          }}
        />
      </Paper>
    </Box>
  );
}
