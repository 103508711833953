import { Link, LinkProps } from "@mui/material";
import omit from "lodash.omit";
import { NavLink as RouterLink } from "react-router-dom";
import CustomButton, { CustomButtonProps } from "./CustomButton";

interface LinkButtonProps extends CustomButtonProps {
  to: string;
  LinkProps?: LinkProps;
}

export default function LinkButton(props: LinkButtonProps) {
  return (
    <Link
      {...props.LinkProps}
      tabIndex={-1}
      sx={{
        color: "#fff",
        textDecoration: "none",
        textDecorationColor: "#fff",
        ...(props.LinkProps?.sx || {})
      }}
      component={RouterLink}
      to={props.to}
    >
      <CustomButton {...omit(props, ["to", "LinkProps"])} />
    </Link>
  );
}
