import {
  Box,
  Fade,
  Link,
  Paper,
  Typography,
  useMediaQuery
} from "@mui/material";
import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

import styles from "./Home.module.css";

import { PushPin, Update } from "@mui/icons-material";
import { BoxProps } from "@mui/system";
import relativeDate from "tiny-relative-date";
import texturedDark from "./../assets/textured-dark.jpg";
import texturedWhite from "./../assets/textured-white.jpg";

import { PostOrPage } from "@tryghost/content-api";
import useGhost from "../blog/useGhost";
import useDarkMode from "../helpers/useDarkMode";
import useLocationHistory from "../helpers/useLocationHistory";
import ConwayProject from "../projects/conway/ConwayProject";

function Bio({ size }: { size: "small" | "large" }) {
  const betweenSmAndMd = useMediaQuery("(max-width:1100px)");
  const bioSquashed = size === "large" && betweenSmAndMd;

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: bioSquashed ? "column" : "row",
        alignItems: "center",
        border: 1,
        borderRadius: 1,
        borderColor: "secondary.main",
        padding: size === "large" ? 3 : 1,
        maxWidth: {
          sm: "400px",
          md: "600px"
        }
      }}
    >
      {/* <img alt="" src={siteIcon} style={{width: size === "large" ? "100px" : "50px", marginRight: size === "large" ? "24px" : "9px"}}/> */}
      {/* <EmojiPeople sx={{mb: 2, mt: 2, mr: size === "large" ? 4 : 2, ml: size === "large" ? 4 : 2, fontSize: size === "large" ? "5rem" : "2.1875rem"}} color="primary"/> */}
      <Typography
        textAlign="center"
        variant={size === "large" ? "h4" : "body1"}
      >
        Welcome to my site! Read a post{" "}
        {size === "large" ? "on the left" : "below"}, visit my
        <Link
          sx={{
            color: "primary.dark",
            textDecoration: "none"
          }}
          component={RouterLink}
          to="/poetry"
        >
          {" "}
          poetry gallery{" "}
        </Link>
        or see some{" "}
        <Link
          sx={{
            color: "primary.dark",
            textDecoration: "none"
          }}
          component={RouterLink}
          to="/projects"
        >
          programming projects.
        </Link>
      </Typography>
    </Paper>
  );
}

// svg from (?) https://wagyumcguires.com/
// https://z0sqrs-a.akamaihd.net/4581_mcguiressushi/Mask-Group-13.svg
interface SinglePostLinkProps extends BoxProps {
  post: PostOrPage;
}

// eslint-disable-next-line react/display-name
const SinglePostLink = React.forwardRef(
  ({ post, ...props }: SinglePostLinkProps, ref) => {
    const darkMode = useDarkMode();

    return (
      <Link
        to={`/blog/${post.slug}`}
        component={RouterLink}
        sx={{
          textDecoration: "none",
          mb: 3,
          borderRadius: 1,
          background: `url(${darkMode ? texturedDark : texturedWhite})`,
          ...props.sx
        }}
      >
        <Box
          {...props}
          ref={ref}
          sx={{
            padding: 3,
            width: {
              sm: "400px"
            },
            maxWidth: "100%",
            "&::before": {
              backgroundImage: `url(${darkMode ? texturedDark : texturedWhite})`
            },
            "&::after": {
              backgroundImage: `url(${darkMode ? texturedDark : texturedWhite})`
            },
            border: "1px solid",
            borderColor: "secondary.main"
          }}
          className={styles["fancy-hover"]}
        >
          {post.featured && (
            <PushPin
              sx={{
                position: "absolute",
                right: 6,
                top: 6,
                transform: "rotate(45deg)"
              }}
            />
          )}
          {post.published_at && (
            <Box
              sx={{
                position: "absolute",
                right: 6,
                bottom: 6,
                color: "gray",
                display: "flex",
                alignItems: "center"
              }}
            >
              <Typography
                sx={{
                  fontStyle: "italic"
                }}
              >
                <Update sx={{ fontSize: "1em", verticalAlign: "middle" }} />{" "}
                {relativeDate(new Date(post.published_at))}
              </Typography>
            </Box>
          )}
          <Paper
            elevation={0}
            sx={{ backgroundColor: "unset", backgroundImage: "unset" }}
          >
            <Typography variant="h4">{post.title}</Typography>

            <Box
              sx={{
                "& blockquote": {
                  m: 0,
                  mt: 2.5,
                  mb: 1.5,
                  borderLeftColor: "primary.main",
                  borderLeftWidth: 2
                }
              }}
            >
              <blockquote>
                <Typography color="gray">{post.excerpt}</Typography>
              </blockquote>
            </Box>
          </Paper>
        </Box>
      </Link>
    );
  }
);

export default function Home() {
  const postList = useGhost((state) => state.postList).sort((a, b) =>
    a.featured === b.featured ? 0 : b.featured ? 1 : -1
  );

  useEffect(() => {
    document.title = "Distant Land";

    useGhost.getState().requestPostList();
  }, []);

  const locationHistory = useLocationHistory((state) => state.locationHistory);

  //const tagCloudData = Object.entries(tags).map(([tag, count]) => ({value: tag, count: count!}))
  const tagCloudData = [
    { value: "JavaScript", count: 38 },
    { value: "React", count: 30 },
    { value: "Nodejs", count: 28 },
    { value: "Express.js", count: 25 },
    { value: "HTML5", count: 33 },
    { value: "MongoDB", count: 18 },
    { value: "CSS3", count: 20 }
  ];

  return (
    <>
      <Box
        position="relative"
        zIndex={1}
        display="flex"
        alignItems="start"
        justifyContent="start"
        style={{
          width: "100%"
        }}
      >
        <Box
          sx={{
            m: {
              xs: 3,
              sm: 6
            },
            alignItems: {
              xs: "stretch",
              sm: "start"
            },
            display: "flex",
            flexDirection: "column"
            //rowGap: 3 // doesn't seem to work on safari D:
          }}
        >
          <Box sx={{ display: { md: "none" }, mb: 3 }}>
            <Bio size="small" />
          </Box>

          {postList.length === 0 && (
            <Box
              sx={{
                width: {
                  sm: "400px"
                }
              }}
            ></Box>
          )}

          {postList.map((post, index) =>
            locationHistory.length === 0 ? (
              <Fade
                // only transition if this is the first page a user vists
                key={post.slug}
                in={true}
                timeout={(index + 1) * 400}
                easing="ease-in"
              >
                <SinglePostLink post={post} />
              </Fade>
            ) : (
              <SinglePostLink key={post.slug} post={post} />
            )
          )}
        </Box>

        <Box
          sx={{
            height: "100vh",
            flexGrow: 1,
            backgroundSize: "cover"
          }}
        >
          <Box
            sx={{
              maxWidth: "650px",
              mt: "10vh",
              ml: "5vw",
              display: { xs: "none", md: "flex" },
              flexDirection: "column",
              //position: "fixed",
              mr: 3,
              rowGap: 6
            }}
          >
            <Bio size="large" />

            <Box
              sx={{
                border: 1,
                borderRadius: 1,
                borderColor: "secondary.main",
                overflow: "hidden",
                maxWidth: "600px"
              }}
            >
              <ConwayProject isPreview={true} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
