import { Box, Paper, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import useGhost from "./useGhost";

import CommentsSection from "../components/comments/CommentsSection";

import hljs from "highlight.js";
import useDarkMode from "../helpers/useDarkMode";
import "./ViewPost.scss";

export default function ViewPost() {
  const params = useParams();

  const urlSlug = params["urlSlug"];

  const containmentUnitRef = useRef<HTMLDivElement>(null);

  // TODO: better logic around urlSlug
  const post = useGhost((state) => (urlSlug ? state.posts[urlSlug] : null));

  useEffect(() => {
    if (urlSlug) {
      useGhost.getState().requestPost(urlSlug);
    }
  }, [urlSlug]);

  useEffect(() => {
    if (post && post.html && containmentUnitRef.current) {
      containmentUnitRef.current.innerHTML = post.html;

      hljs.highlightAll();
    }
  }, [post]);

  const darkMode = useDarkMode();

  return (
    <>
      {post && (
        <Box
          component="article"
          sx={{ display: "flex", width: "100%", maxWidth: "100%" }}
        >
          <Box sx={{ width: "100%", maxWidth: "100%" }}>
            <Paper
              sx={{
                //backgroundColor: "rgba(255, 255, 255, .30)",
                backgroundImage: "unset", // override the default
                backgroundColor: "unset", // override the default
                backdropFilter: "blur(25px)",
                border: {
                  xs: 0,
                  sm: 1
                },
                borderRadius: 1,
                borderColor: {
                  sm: "secondary.main"
                },
                m: {
                  xs: 0.5,
                  sm: 6
                },
                p: {
                  xs: 1,
                  sm: 1
                },
                maxWidth: "1000px",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Typography textAlign="center" mb={1} variant="h3">
                {post.title}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignSelf: "center",
                  rowGap: 1,
                  columnGap: 1,
                  mt: 1,
                  mb: 1
                }}
              >
                <Typography
                  sx={{
                    borderColor: "primary.main"
                  }}
                  borderRadius={1}
                  border={1}
                  pl={0.5}
                  pr={0.5}
                  variant="body2"
                >
                  ~{post.reading_time} minute read
                </Typography>
                {post.tags?.map((tag) => (
                  <Typography
                    key={tag.id}
                    sx={{
                      borderColor: "primary.main"
                    }}
                    borderRadius={1}
                    border={1}
                    pl={0.5}
                    pr={0.5}
                    variant="body2"
                  >
                    {tag.name}
                  </Typography>
                ))}
              </Box>

              <Box
                sx={{
                  "& img": {
                    height: "auto",
                    width: "auto",
                    maxWidth: "100%",
                    maxHeight: "450px"
                  },
                  "& .kg-image-card": {
                    display: "flex",
                    justifyContent: "center"
                  },
                  "& .kg-callout-card": {
                    //display: "flex",
                    columnGap: 2,
                    m: {
                      xs: 0,
                      sm: 1
                    },
                    p: 1,
                    pl: 2,
                    pr: 2,
                    border: 1,
                    borderRadius: 1,
                    borderColor: "primary.main"
                  },
                  "& .kg-callout-emoji": {
                    fontSize: "1.5em",
                    pt: 1,
                    pr: 2,
                    float: "left"
                  },
                  "& .kg-callout-card-yellow": {
                    borderColor: "warning.main"
                  }
                }}
                id="ghost-containment-unit"
                data-theme={darkMode ? "dark" : "light"}
                ref={containmentUnitRef}
              ></Box>
            </Paper>

            <Paper
              sx={{
                border: {
                  xs: 0,
                  sm: 1
                },
                borderRadius: 1,
                borderColor: {
                  sm: "secondary.main"
                },
                m: {
                  xs: 0,
                  sm: 6
                },
                p: {
                  xs: 1,
                  sm: 1
                },
                maxWidth: "1000px",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <CommentsSection resource={`/blog/${post.slug}`} />
            </Paper>
          </Box>

          {post.feature_image && (
            <Box
              sx={{
                height: "100%",
                width: "100%",
                maxWidth: "100%",
                display: {
                  xs: "none",
                  xl: "inline"
                },
                m: 6,
                ml: 0,
                border: post.feature_image.includes("no_border") ? 0 : 1,
                borderRadius: 1,
                borderColor: "secondary.main",
                overflow: "hidden"
              }}
            >
              <img
                style={{
                  // position: "fixed",
                  width: "auto",
                  height: "auto",
                  maxHeight: "100%",
                  maxWidth: "100%",
                  display: "block"
                }}
                src={post.feature_image}
                alt={post.feature_image_alt || undefined}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
