import { create } from "zustand";

interface LocationState {
  currentLocation: string | undefined;
  locationHistory: string[];
  setNewLocation: (newLocation: string) => void;
}

const useLocationHistory = create<LocationState>((set) => ({
  currentLocation: undefined,
  locationHistory: [],
  setNewLocation: (newLocation: string) =>
    set((state) => {
      const copiedHistory = [...state.locationHistory];

      if (state.currentLocation && newLocation !== state.currentLocation) {
        copiedHistory.unshift(state.currentLocation);
      }

      return {
        currentLocation: newLocation,
        locationHistory: copiedHistory
      };
    })
}));

export default useLocationHistory;
