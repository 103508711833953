import { Refresh } from "@mui/icons-material";
import { Box, createTheme, LinearProgress, styled } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CustomButton from "../../helpers/CustomButton";
import useUIState from "../../helpers/useUIState";
import trackEvent from "../../services/analyticsService";
import render from "./mandelbrot";
interface MandelbotProjectProps {
  isPreview?: boolean;
}

const FastLinearProgress = styled(LinearProgress)(() => ({
  bar: {
    transition: "none"
  }
}));

export default function MandelbotProject(props: MandelbotProjectProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const renderRef = useRef<{ cancelRender: () => void } | null>(null);

  const [progress, setProgress] = useState(0);

  function updateProgress(progress: number) {
    setProgress(Math.round(progress));
  }

  function createNew() {
    renderRef.current?.cancelRender();
    const c = canvasRef.current;

    if (c) {
      // scale to lie in x (-2.5, 1) and y (-1, 1)]
      c.width = props.isPreview ? 980 : window.innerWidth;
      c.height = Math.ceil((4 / 7) * c.width);
      renderRef.current = {
        cancelRender: render(updateProgress, c, 0, 0, c.height / 2, true)
      };
    }
  }

  function reset() {
    createNew();
    trackEvent({
      event: "Mandelbrot Engagement",
      properties: { action: "reset" }
    });
  }

  useEffect(() => {
    createNew();

    return function cleanup() {
      renderRef.current?.cancelRender();
    };
  }, []);

  const styles = {
    disableTransitions: {
      transition: "none"
    }
  };

  const customTheme = createTheme({
    components: {
      MuiLinearProgress: {
        styleOverrides: {
          bar: {
            transition: "none"
          }
        }
      }
    }
  });

  const texturedBackgroundImage = useUIState(
    (state) => state.texturedBackgroundImage
  );

  return (
    <>
      <Box display="grid">
        <Box
          style={{
            gridArea: "1/1",
            alignItems: "start",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <CustomButton
            variant="outlined"
            color="secondary"
            sx={{
              m: 2,
              backgroundImage: texturedBackgroundImage,
              //backgroundColor: darkMode ? "black" : "white",
              "&:hover": {
                //backgroundColor: darkMode ? "black" : "white"
              }
            }}
            endIcon={<Refresh />}
            onClick={reset}
          >
            Reset Fractal
          </CustomButton>
        </Box>

        <Box style={{ gridArea: "1/1" }}>
          <FastLinearProgress
            sx={{
              "& .MuiLinearProgress-bar": {
                transition: "none"
              }
            }}
            variant="determinate"
            color="secondary"
            value={progress}
          />
        </Box>

        <Box
          sx={{
            width: "100%",
            "&:hover": { cursor: "zoom-in" },
            gridArea: "1/1"
          }}
        >
          <canvas style={{ width: "100%", display: "block" }} ref={canvasRef}>
            Your browser does not support the HTML5 canvas tag. Stop using
            Netscape.
          </canvas>
        </Box>
      </Box>
    </>
  );
}
