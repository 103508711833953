import {
  addCard,
  Column,
  ControlledBoard,
  KanbanBoard,
  moveCard,
  moveColumn,
  OnDragEndNotification
} from "@caldwell619/react-kanban";
import {
  Box,
  Button,
  Chip,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  PaperProps,
  Select,
  Typography,
  useTheme
} from "@mui/material";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { DevelopCard } from "./developTypes";

import { useImmer } from "use-immer";

// directly use styles from repo, since they're not in dist. https://github.com/christopher-caldwell/react-kanban/issues
import { Add, Check, Edit } from "@mui/icons-material";
import { Form, Formik } from "formik";
import FormikField from "../../components/formik/FormikField";
import "./kanban-styles.scss";

import "@caldwell619/react-kanban/dist/styles.css";

import AnimateHeight from "react-animate-height";

import { v4 as uuidv4 } from "uuid";
import useUIState from "../../helpers/useUIState";

interface DevelopProjectProps {
  isPreview?: boolean;
}

const initialBoard: KanbanBoard<DevelopCard> = {
  columns: [
    {
      id: 1,
      title: "Todo",
      cards: [
        {
          id: 1,
          title: "Persist changes",
          description:
            "Write changes to the DB and create a system for fetching cards/column layout.",
          priority: "high"
        },
        {
          id: 2,
          title: "Add commenting feature",
          description:
            "It would be great to link into the existing commenting feature to allow guests to comment on arbitrary cards! Since we made comments generic to resources, this shouldn't be that hard at all.",
          priority: "low"
        }
      ]
    },
    {
      id: 2,
      title: "Doing",
      cards: [
        {
          id: 3,
          title: "Polish up CSS",
          description:
            "See if we can get a nice flex-wrap or media queries for a column layout on small screens",
          priority: "medium"
        }
      ]
    },
    {
      id: 3,
      title: "Done",
      cards: [
        {
          id: 5,
          title: "Auto height transitions",
          description:
            "Get auto height transitions for cards on the kanban board, so when editing them it nicely expands/shrinks",
          priority: "low"
        }
      ]
    }
  ]
};

interface AdderProps {
  column: Column<DevelopCard>;
  onConfirm: (column: Column<DevelopCard>, card: DevelopCard) => void;
}

const colorForPriority = {
  "": "grey.400",
  unprioritized: "grey.500",
  low: "success.main",
  medium: "info.main",
  high: "error.main"
};

interface RenderCardProps extends PaperProps {
  card: DevelopCard;
  cardBag: { dragging: boolean };
  onEdit: (finalHeight: number) => void;
  cardAnimationManagerRef: React.MutableRefObject<CardAnimationManager>;
}

function RenderCard({ card, cardBag, onEdit, ...props }: RenderCardProps) {
  const darkMode = useUIState((state) => state.darkMode);

  const [hasRendered, setHasRendered] = useState(false);

  const rootDivRef = useRef<HTMLDivElement>(null);

  const [pendingAnimations] = useState(
    props.cardAnimationManagerRef.current.pendingAnimations[card.id] || {}
  );

  const [wasJustDragged] = useState(
    props.cardAnimationManagerRef.current.pendingAnimations[card.id]
      ?.wasJustDragged
  );

  // TODO: use effect instead.

  if (cardBag.dragging) {
    if (props.cardAnimationManagerRef.current.pendingAnimations[card.id]) {
      props.cardAnimationManagerRef.current.pendingAnimations[
        card.id
      ].wasJustDragged = true;
    } else {
      props.cardAnimationManagerRef.current.pendingAnimations[card.id] = {
        wasJustDragged: true
      };
    }
  }

  const [maxHeight, setMaxHeight] = useState(
    pendingAnimations?.transitioningFromHeight || 0
  );
  const [minHeight, setMinHeight] = useState(
    pendingAnimations?.transitioningFromHeight || 0
  );

  useEffect(() => {
    setMaxHeight((initial) => initial * 4);
    setMinHeight((initial) => 50);
    setHasRendered(true);

    delete props.cardAnimationManagerRef.current.pendingAnimations[card.id];
  }, []);

  const theme = useTheme();

  return (
    <Paper
      elevation={
        cardBag.dragging || (pendingAnimations.wasJustDragged && !hasRendered)
          ? 10
          : 1
      }
      ref={rootDivRef}
      className="kanban-card"
      sx={{
        transition:
          "border-color 0.5s, max-height 1s, min-height 1s, height 1s, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%",
        width: "250px",
        //minHeight: pendingAnimations.transitioningFromHeight === undefined ? "50px" : minHeight,
        //maxHeight: pendingAnimations.transitioningFromHeight === undefined ? "unset" : maxHeight,
        mb: "7px",
        backgroundImage: "none",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        position: "relative",
        p: darkMode ? 1 : `calc(${theme.spacing(1)} + 1px)`,
        pl: 1,
        border: darkMode ? 1 : 0,
        ml: 0,
        borderLeft: 3,
        borderColor: darkMode ? colorForPriority[card.priority] : "transparent",
        borderLeftColor: colorForPriority[card.priority],
        "&:before": {
          transition: "border-color .5s",
          borderRadius: 1,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          content: '""',
          position: "absolute",
          left: "-2px",
          right: "-1px",
          top: "-1.5px",
          bottom: "-1px",
          border: 1,
          borderColor: darkMode
            ? colorForPriority[card.priority]
            : "transparent"
        },
        "&:hover .edit-card-button": {
          opacity: 1
        }
      }}
      {...props}
    >
      <AnimateHeight
        duration={250}
        height={
          pendingAnimations.transitioningFromHeight
            ? hasRendered
              ? "auto"
              : pendingAnimations.transitioningFromHeight
            : "auto"
        }
      >
        <IconButton
          className="edit-card-button"
          onClick={() =>
            onEdit(
              rootDivRef.current ? heightIncludingMargin(rootDivRef.current) : 0
            )
          }
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            opacity: 0,
            transition: "opacity 0.4s"
          }}
        >
          <Edit />
        </IconButton>

        <Typography
          sx={{
            //marginY: hasRendered || (!props.initialHeight) ? 0 : 1,
            transition: "margin 1s",
            pb: 1
          }}
        >
          {card.title}
        </Typography>

        <Typography
          sx={{ mt: 1.3, whiteSpace: "pre-wrap" }}
          color="text.secondary"
        >
          {card.description}
        </Typography>

        {/* </ContentEditable> */}
      </AnimateHeight>
    </Paper>
  );
}

type AddOrEditCardProps =
  | {
      type: "edit";
      onDiscard: (finalHeight: number) => void;
      editing: { card: DevelopCard; cardBag: { dragging: boolean } };
      onUpdate: (updatedCard: DevelopCard, finalHeight: number) => void;
      cardAnimationManagerRef: React.MutableRefObject<CardAnimationManager>;
    }
  | {
      type: "add";
      onDiscard: () => void;
      column: Column<DevelopCard>;
      onCardAdd: (column: Column<DevelopCard>, card: DevelopCard) => void;
    };

function AddOrEditCard(props: AddOrEditCardProps) {
  interface FormFields
    extends Omit<DevelopCard, "id" | "content" | "priority"> {
    priority: DevelopCard["priority"] | "";
  }

  const card = "editing" in props ? props.editing?.card : undefined;
  const cardBag = "editing" in props ? props.editing?.cardBag : undefined;

  const darkMode = useUIState((state) => state.darkMode);

  const [drawAttention, setDrawAttention] = useState(false);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);

  const [pendingAnimations] = useState(
    props.type === "edit"
      ? props.cardAnimationManagerRef.current.pendingAnimations[
          props.editing.card.id
        ]
      : undefined
  );

  const [wasJustDragged] = useState(
    props.type === "edit" &&
      props.cardAnimationManagerRef.current.pendingAnimations[
        props.editing.card.id
      ]?.wasJustDragged
  );

  // TODO: use effect instead.
  if (props.type === "edit" && props.editing.cardBag.dragging) {
    if (
      props.cardAnimationManagerRef.current.pendingAnimations[
        props.editing.card.id
      ]
    ) {
      props.cardAnimationManagerRef.current.pendingAnimations[
        props.editing.card.id
      ].wasJustDragged = true;
    } else {
      props.cardAnimationManagerRef.current.pendingAnimations[
        props.editing.card.id
      ] = { wasJustDragged: true };
    }
  }

  const [maxHeight, setMaxHeight] = useState(
    pendingAnimations?.transitioningFromHeight || 0
  );

  const [hasRendered, setHasRendered] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.type === "edit") {
      setMaxHeight((initial) => initial * 2 + 200);
      setHasRendered(true);

      // clear pending animations
      // TODO: may need to add timeout to this because of StrictMode?
      delete props.cardAnimationManagerRef.current.pendingAnimations[
        props.editing.card.id
      ];
    }
  }, [props.type]);

  useEffect(() => {
    if (drawAttention) {
      cancelButtonRef.current?.focus();

      const timeout = setTimeout(() => setDrawAttention(false), 3000);

      return () => clearTimeout(timeout);
    }
  }, [drawAttention]);

  const theme = useTheme();

  return (
    <Formik<FormFields>
      initialValues={{
        title: card?.title || "",
        description: card?.description || "",
        priority: card?.priority || ""
      }}
      onSubmit={(values) => {
        if (props.type === "add") {
          const newCard: DevelopCard = {
            ...values,
            id: uuidv4(),
            priority: values.priority || "unprioritized"
          };

          props.onCardAdd(props.column, newCard);
        } else {
          // editing existing card

          const updatedCard: DevelopCard = {
            ...props.editing?.card,
            ...values,
            priority: values.priority || "unprioritized"
          };

          props.onUpdate(
            updatedCard,
            wrapperRef.current
              ? heightIncludingMargin(wrapperRef.current) - 14
              : 0
          );
        }
      }}
    >
      {({ handleChange, handleBlur, values, dirty }) => (
        <Form style={{ maxWidth: "100%", zIndex: 1201, position: "relative" }}>
          <Paper
            elevation={
              cardBag?.dragging ||
              (pendingAnimations?.wasJustDragged && !hasRendered)
                ? 10
                : 1
            }
            ref={wrapperRef}
            sx={{
              mb: "7px",
              //maxHeight: props.type === "edit" ? maxHeight : "unset",
              transition: "border-color .5s, max-height 1s",
              p: darkMode ? 1 : `calc(${theme.spacing(1)} + 1px)`,
              pl: 1,
              maxWidth: "100%",
              width: "250px",
              border: darkMode ? 1 : 0,
              ml: 0,
              borderColor:
                values.priority === ""
                  ? darkMode
                    ? colorForPriority[values.priority]
                    : "transparent"
                  : darkMode
                  ? colorForPriority[values.priority]
                  : "transparent",
              borderLeft: 3,
              borderLeftColor:
                values.priority === ""
                  ? "transparent"
                  : colorForPriority[values.priority],
              backgroundImage: "none",
              borderTopLeftRadius: props.type === "add" ? 4 : 0,
              borderBottomLeftRadius: 0,
              position: "relative",
              "&:before": {
                transition: "border-color .5s",
                borderRadius: 1,
                borderTopLeftRadius: props.type === "add" ? 4 : 0,
                borderBottomLeftRadius: 0,
                content: '""',
                position: "absolute",
                left: "-2px",
                right: "-1px",
                top: "-1.5px",
                bottom: "-1px",
                border: 1,
                borderColor:
                  values.priority === ""
                    ? darkMode
                      ? colorForPriority[values.priority]
                      : "transparent"
                    : darkMode
                    ? colorForPriority[values.priority]
                    : "transparent"
              }
            }}
          >
            <AnimateHeight
              duration={250}
              height={
                pendingAnimations?.transitioningFromHeight
                  ? hasRendered
                    ? "auto"
                    : pendingAnimations.transitioningFromHeight
                  : "auto"
              }
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                {props.type === "edit" && (
                  <IconButton
                    className="edit-card-button"
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      opacity: hasRendered ? 0 : 1,
                      transition: "opacity 0.4s"
                    }}
                  >
                    <Edit />
                  </IconButton>
                )}

                <FormikField
                  sx={{
                    //marginY: hasRendered || !shouldAnimate ? 1 : 0,
                    "& .MuiInputBase-input": {
                      padding: 0,
                      pb: 1
                    },
                    transition: "margin 1s"
                  }}
                  name="title"
                  placeholder="Title"
                  label=""
                  variant="standard"
                />

                <FormikField
                  sx={{ mt: 1 }}
                  name="description"
                  placeholder="Description"
                  label=""
                  variant="standard"
                  multiline
                  minRows={3}
                />

                <FormControl sx={{ mt: 2 }} fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    Priority
                  </InputLabel>
                  <Select
                    value={values.priority}
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="priority"
                    label="Priority"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem value="unprioritized">
                      <em>unprioritized</em>
                    </MenuItem>
                    <MenuItem value="low">Low</MenuItem>
                    <MenuItem value="medium">Medium</MenuItem>
                    <MenuItem value="high">High</MenuItem>
                  </Select>
                </FormControl>

                {/* </ContentEditable> */}

                {props.type === "edit" && (
                  <Box sx={{ display: "flex" }}>
                    <Button
                      disabled={!dirty}
                      color="success"
                      type="submit"
                      startIcon={<Check />}
                    >
                      Update
                    </Button>

                    <Button
                      color="error"
                      sx={{ ml: "auto" }}
                      onClick={() =>
                        props.onDiscard(
                          wrapperRef.current
                            ? heightIncludingMargin(wrapperRef.current) - 14
                            : 0
                        )
                      }
                    >
                      Cancel
                    </Button>
                  </Box>
                )}

                {props.type === "add" && (
                  <Box sx={{ display: "flex" }}>
                    <Button
                      disabled={!dirty}
                      color="success"
                      type="submit"
                      startIcon={<Add />}
                    >
                      Add Card
                    </Button>

                    <Button
                      color="error"
                      ref={cancelButtonRef}
                      onClick={props.onDiscard}
                      sx={{ ml: "auto" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                )}
              </Box>
            </AnimateHeight>
          </Paper>
        </Form>
      )}
    </Formik>
  );
}

// This needs to be overridden, default value is invalid and will cause errors
const kanbanContext = createContext<KanbanContext>(null as any);

window.kanbanContext = kanbanContext;

declare global {
  interface Window {
    kanbanContext: React.Context<KanbanContext>;
  }
}

interface KanbanContext {
  KanbanColumnComponent: any;
  kanbanColumnComponentProps: any;
  customRenderColumnHeader: (column: Column<DevelopCard>) => JSX.Element;
  CustomRenderCardAdder: (props: AdderProps) => JSX.Element;
}

function customColumnHeader(column: Column<DevelopCard>) {
  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        justifyContent: "center",
        p: 2,
        marginX: -2
      }}
    >
      <Chip
        size="small"
        sx={{
          borderRadius: 0,
          borderBottomLeftRadius: 4,
          borderTopRightRadius: 4,
          position: "absolute",
          top: 0,
          right: 0
        }}
        label={`count: ${column.cards.length}`}
      />
      <Typography variant="h6" className="kanban-header">
        {column.title}
      </Typography>
    </Box>
  );
}

function heightIncludingMargin(el: HTMLElement) {
  let elHeight = el.offsetHeight;
  elHeight += parseInt(
    window.getComputedStyle(el).getPropertyValue("margin-top")
  );
  elHeight += parseInt(
    window.getComputedStyle(el).getPropertyValue("margin-bottom")
  );

  return elHeight;
}

type CardId = number | string;

interface PendingAnimationsForCard {
  transitioningFromHeight?: number;
  wasJustDragged?: boolean;
}

interface CardAnimationManager {
  pendingAnimations: Record<CardId, PendingAnimationsForCard>;
}

export default function DevelopProject({
  isPreview = false
}: DevelopProjectProps) {
  const [board, setBoard] = useImmer<KanbanBoard<DevelopCard>>(initialBoard);

  const handleCardMove: OnDragEndNotification<DevelopCard> = (
    _card,
    source,
    destination
  ) => {
    setBoard((currentBoard) => moveCard(currentBoard, source, destination));
  };

  const handleColumnMove: OnDragEndNotification<Column<DevelopCard>> = (
    _column,
    source,
    destination
  ) => {
    setBoard((currentBoard) => moveColumn(currentBoard, source, destination));
  };

  const handleCardAdd = useCallback(
    (column: Column<DevelopCard>, card: DevelopCard) => {
      setBoard((currentBoard) =>
        addCard(currentBoard, column, card, { on: "top" })
      );
    },
    [setBoard]
  );

  const [editingCard, setEditingCard] = useState<string | number | undefined>(
    undefined
  );

  const customRenderCardAdder = useMemo(() => {
    function CustomAdder(props: AdderProps) {
      const [addingCard, setAddingCard] = useState(false);

      return (
        <Box sx={{ display: "grid" }}>
          <Paper
            elevation={0}
            sx={{
              gridArea: "1/1",
              display: "flex",
              border: 1,
              borderColor: "text.secondary",
              mb: 2
            }}
          >
            <IconButton
              onClick={() => setAddingCard(true)}
              sx={{ width: "100%", height: "100%", borderRadius: 1 }}
            >
              <Add />
            </IconButton>
          </Paper>
          <Collapse sx={{ gridArea: "1/1" }} in={addingCard} unmountOnExit>
            {" "}
            {/* collapsedSize={"40px"}  */}
            <AddOrEditCard
              type="add"
              onDiscard={() => setAddingCard(false)}
              onCardAdd={(column, card) => {
                setAddingCard(false);
                handleCardAdd(column, card);
              }}
              column={props.column}
            />
          </Collapse>
        </Box>
      );
    }

    return CustomAdder;
  }, [handleCardAdd]);

  function handleCardUpdate(updatedCard: DevelopCard) {
    console.log("updated: ", updatedCard);

    setBoard((draft) => {
      const allCards = draft.columns.flatMap((column) => column.cards);

      const existingCardIndex = allCards.findIndex(
        (card) => card.id === updatedCard.id
      );

      if (existingCardIndex === -1) {
        console.error(
          "Tried to update a card, but it was not found in the board state."
        );
      } else {
        const existingCard = allCards[existingCardIndex];

        Object.entries(updatedCard).forEach(([k, v]) => {
          (existingCard as any)[k] = v;
        });
      }
    });
  }

  const contextValue = useMemo(
    () => ({
      KanbanColumnComponent: Paper,
      kanbanColumnComponentProps: {
        sx: {
          border: 1,
          borderRadius: 1,
          borderColor: "secondary.main",
          minWidth: "270px",
          pt: 0
        }
      },
      customRenderColumnHeader: customColumnHeader,
      CustomRenderCardAdder: customRenderCardAdder
    }),
    [customRenderCardAdder]
  );

  const cardAnimationManagerRef = useRef<CardAnimationManager>({
    pendingAnimations: {}
  });

  function mutablePendingAnimationForCard(
    cardId: CardId
  ): PendingAnimationsForCard {
    // FIXME: change typescript to give an undefined here
    const maybeExisting =
      cardAnimationManagerRef.current.pendingAnimations[cardId];

    if (cardAnimationManagerRef.current.pendingAnimations[cardId]) {
      return cardAnimationManagerRef.current.pendingAnimations[cardId];
    } else {
      cardAnimationManagerRef.current.pendingAnimations[cardId] = {}; // set the object so it's not undefined
      return cardAnimationManagerRef.current.pendingAnimations[cardId];
    }
  }

  return (
    <Box
      sx={{
        maxHeight: "100%",
        ...(isPreview && {
          aspectRatio: "49/28",
          width: 545,
          height: "auto",
          maxHeight: "311.433px",
          overflow: "hidden"
        })
      }}
    >
      <kanbanContext.Provider value={contextValue}>
        <ControlledBoard<DevelopCard>
          onCardDragEnd={handleCardMove}
          onColumnDragEnd={handleColumnMove}
          renderCard={(card, cardBag) => (
            <>
              {editingCard === card.id && (
                <AddOrEditCard
                  type="edit"
                  onUpdate={(updatedCard, finalHeight) => {
                    handleCardUpdate(updatedCard);
                    setEditingCard(undefined);
                    const pendingAnimation = mutablePendingAnimationForCard(
                      card.id
                    );
                    pendingAnimation.transitioningFromHeight = finalHeight;
                  }}
                  onDiscard={(finalHeight) => {
                    setEditingCard(undefined);
                    const pendingAnimation = mutablePendingAnimationForCard(
                      card.id
                    );
                    pendingAnimation.transitioningFromHeight = finalHeight;
                  }}
                  editing={{ card, cardBag }}
                  cardAnimationManagerRef={cardAnimationManagerRef}
                />
              )}

              {editingCard !== card.id && (
                <RenderCard
                  cardAnimationManagerRef={cardAnimationManagerRef}
                  onEdit={(finalHeight) => {
                    setEditingCard(card.id);
                    const pendingAnimation = mutablePendingAnimationForCard(
                      card.id
                    );
                    pendingAnimation.transitioningFromHeight = finalHeight;
                  }}
                  onDoubleClick={(event) => {
                    setEditingCard(card.id);
                    const pendingAnimation = mutablePendingAnimationForCard(
                      card.id
                    );
                    pendingAnimation.transitioningFromHeight =
                      heightIncludingMargin(event.currentTarget);
                  }}
                  card={card}
                  cardBag={cardBag}
                />
              )}
            </>
          )}
        >
          {board}
        </ControlledBoard>
      </kanbanContext.Provider>
    </Box>
  );
}
