// TODO: re-enable the linter, and clean up this code
/* eslint-disable @typescript-eslint/no-unused-vars */

import trackEvent from "../../services/analyticsService";

function colorStylePattern(color: Color) {
  return (
    "rgba(" + color.r + ", " + color.g + ", " + color.b + ", " + color.a + ")"
  );
}

interface Color {
  r: number;
  g: number;
  b: number;
  a: number;
}

function constructColor(r: number, g: number, b: number, a: number): Color {
  return {
    r: r,
    g: g,
    b: b,
    a: a
  };
}

function constructBlack() {
  return constructColor(0, 0, 0, 1);
}

// creates a random RGBA value. If opacity is true, opacity will be randomized as well-- otherwise it will be 1.
function constructRandomColor(opacity: boolean): Color {
  const r = Math.round(Math.random() * 255);
  const g = Math.round(Math.random() * 255);
  const b = Math.round(Math.random() * 255);
  const a = opacity ? Math.random() : 1;

  return {
    r: r,
    g: g,
    b: b,
    a: a
  };
}
function bluePalette(length: number): Color[] {
  const x = new Array(length);

  const growthFactor = 0.2;
  for (let i = 0; i < length - 1; i++) {
    const blue = Math.round(
      (Math.pow(i, growthFactor) / Math.pow(length, growthFactor)) * 200
    );
    x[i] = constructColor(
      0,
      200 - Math.round((i / length) * 200),
      55 + blue,
      1
    );
  }
  x[length - 1] = constructBlack();
  return x;
}

function reverseBluePalette(length: number): Color[] {
  const x = new Array(length);

  for (let i = 0; i < length - 1; i++) {
    x[i] = constructColor(0, 0, Math.round(100 + (i / length) * 255), 1);
  }

  x[length - 1] = constructBlack();

  return x;
}

function reversedPalette(length: number): Color[] {
  const x = new Array(length);

  const growthFactor = 1;

  for (let i = 0; i < length - 1; i++) {
    const blue = Math.round(
      (Math.pow(i, growthFactor) / Math.pow(length, growthFactor)) * 255
    );
    x[i] = constructColor(blue, blue, blue, 1);
  }

  x[length - 1] = constructColor(255, 255, 255, 1);
  return x;
}

function coolPalette(length: number): Color[] {
  const x = new Array(length);

  const growthFactor = 1.1;

  for (let i = 0; i < length - 1; i++) {
    const blue = Math.round(
      (Math.pow(i, growthFactor) / Math.pow(length, growthFactor)) * 255
    );
    x[i] = constructColor(255 - blue, 255 - blue, 255 - blue, 15);
  }

  x[length - 1] = constructBlack();
  return x;
}

function randomPalette(length: number): Color[] {
  const x = new Array(length);

  for (let i = 0; i < length - 1; i++) {
    x[i] = constructRandomColor(false);
  }
  x[length - 1] = constructBlack();
  return x;
}

function linearInterpolateColors(a: Color, b: Color, f: number) {
  constructColor(
    a.r + f * (b.r - a.r),
    a.g + f * (b.g - a.g),
    a.b + f * (b.b - a.b),
    a.a + f * (b.a - a.a)
  );
}

function registerCancelledRender(cancelledRenders: number[], id: number) {
  cancelledRenders.push(id);
  // only allow the queue to be of length 50
  if (cancelledRenders.length >= 50) {
    cancelledRenders.shift();
  }
}

function isKnownCancelledRender(cancelledRenders: number[], id: number) {
  return cancelledRenders.some((cancelledId) => cancelledId === id);
}

// c must have an aspect ratio of height = 4/7th height, and the viewport center is at (5/7th width, 1/2 height)
function _render(
  updateProgress: (progress: number) => void,
  firstRender: boolean,
  max_iterations: number,
  xOffset: number,
  yOffset: number,
  scale: number,
  viewportState: ViewportState,
  colored: boolean
) {
  viewportState.rendering = true;
  viewportState.xOffset = xOffset;
  viewportState.yOffset = yOffset;
  viewportState.scale = scale;

  const c = viewportState.c;

  let palette;
  if (colored) {
    //palette = bluePalette(max_iterations);
    palette = coolPalette(max_iterations);
    //palette = randomPalette(max_iterations);
    //palette = reversedPalette(max_iterations);
    //palette = reverseBluePalette(max_iterations);
  } else {
    palette = reversedPalette(max_iterations);
    //palette = coolPalette(max_iterations);
  }

  const buffer: number[][] = new Array(c.width);

  let i;
  for (i = 0; i < c.width; i++) {
    buffer[i] = new Array(c.height);
  }

  let pX;
  let pY;
  for (pX = 0; pX < c.width; pX++) {
    for (pY = 0; pY < c.height; pY++) {
      const complexCoords = convertToComplexPlaneCoords(
        c,
        pX,
        pY,
        xOffset,
        yOffset,
        scale
      );

      let x = 0.0;
      let y = 0.0;
      let iteration = 0;

      while (x * x + y * y < 2 * 2 && iteration < max_iterations) {
        const xTemp = x * x - y * y + complexCoords.x;
        const yTemp = 2 * x * y + complexCoords.y;
        if (x === xTemp && y === yTemp) {
          iteration = max_iterations;
          break;
        }
        y = yTemp;
        x = xTemp;
        iteration = iteration + 1;
      }

      buffer[pX][pY] = iteration;
    }
  }

  const imagedata = viewportState.ctx.createImageData(c.width, c.height);

  // paint the canvas the color of the final image to start
  for (pX = 0; pX < c.width; pX++) {
    for (pY = 0; pY < c.height; pY++) {
      const pixelIndex = (pY * c.width + pX) * 4;

      // Set the pixel data
      imagedata.data[pixelIndex] = palette[max_iterations - 1].r;
      imagedata.data[pixelIndex + 1] = palette[max_iterations - 1].g;
      imagedata.data[pixelIndex + 2] = palette[max_iterations - 1].b;
      imagedata.data[pixelIndex + 3] = palette[max_iterations - 1].a * 255;
    }
  }
  viewportState.ctx.putImageData(imagedata, 0, 0);

  // first render always has an id of 1, so we know how to cancel it
  const id = firstRender ? 1 : Math.round(Math.random() * 100000);
  viewportState.renderId = id;

  const delay = 1;

  renderIteration(
    updateProgress,
    1,
    max_iterations,
    buffer,
    palette,
    c,
    delay,
    id,
    imagedata,
    viewportState
  );
}

function renderIteration(
  updateProgress: (progress: number) => void,
  iteration: number,
  max_iterations: number,
  buffer: number[][],
  palette: Color[],
  canvas: HTMLCanvasElement,
  delay: number,
  renderId: number,
  imagedata: ImageData,
  viewportState: ViewportState
) {
  updateProgress((iteration / max_iterations) * 100);

  if (isKnownCancelledRender(viewportState.cancelledRenders, renderId)) {
    viewportState.rendering = false;
    return;
  }

  let counter = 0;
  let pX;
  let pY;
  for (pX = 0; pX < canvas.width; pX++) {
    for (pY = 0; pY < canvas.height; pY++) {
      if (buffer[pX][pY] === iteration) {
        counter++;
        const pixelIndex = (pY * canvas.width + pX) * 4;

        // Set the pixel data
        imagedata.data[pixelIndex] = palette[iteration - 1].r;
        imagedata.data[pixelIndex + 1] = palette[iteration - 1].g;
        imagedata.data[pixelIndex + 2] = palette[iteration - 1].b;
        imagedata.data[pixelIndex + 3] = palette[iteration - 1].a * 255;
      }
    }
  }
  viewportState.ctx.putImageData(imagedata, 0, 0);

  if (iteration < max_iterations) {
    setTimeout(function () {
      renderIteration(
        updateProgress,
        iteration + 1,
        max_iterations,
        buffer,
        palette,
        canvas,
        delay,
        renderId,
        imagedata,
        viewportState
      );
    }, delay);
  } else {
    window.console.log("Finished rendering.");
    viewportState.rendering = false;
  }
}

// c must have an aspect ratio of height = 4/7th height, and the viewport center is at (5/7th width, 1/2 height)
export default function render(
  updateProgress: (progress: number) => void,
  c: HTMLCanvasElement,
  xOffset: number,
  yOffset: number,
  scale: number,
  colored: boolean
) {
  let maxIterations = 400;
  const ctx = c.getContext("2d");

  if (!ctx) {
    // FIXME: better error handling. Is this ever reasonably false? Old devices?
    throw Error("Could not get 2d context");
  }

  const viewportState: ViewportState = {
    depth: 0,
    cancelledRenders: [],
    c: c,
    ctx: ctx,
    renderId: 1,
    rendering: false,
    scale: scale,
    xOffset: xOffset,
    yOffset: yOffset
  };
  window.console.log(
    "Doing initial _render with " +
      Math.round(Math.pow(Math.log(maxIterations), 3)) +
      " max iterations."
  );

  // render operation is expensive, so we let it get scheduled for later
  setTimeout(
    () =>
      _render(
        updateProgress,
        true,
        Math.round(Math.pow(Math.log(maxIterations), 3)),
        xOffset,
        yOffset,
        scale,
        viewportState,
        colored
      ),
    1
  );

  function cancelRender() {
    c.removeAttribute("onclick");
    window.console.log("Canceling render #" + viewportState.renderId);
    registerCancelledRender(
      viewportState.cancelledRenders,
      viewportState.renderId
    );
  }

  const zoomLevel = 4;

  c.onclick = function (event) {
    viewportState.depth++;

    trackEvent({
      event: "Mandelbrot Engagement",
      properties: {
        action: "zoom",
        depth: viewportState.depth
      }
    });

    // cancel current render
    registerCancelledRender(
      viewportState.cancelledRenders,
      viewportState.renderId
    );
    viewportState.rendering = true;

    const mousePos = getMousePos(viewportState.c, event);
    const complexCoords = convertToComplexPlaneCoords(
      viewportState.c,
      mousePos.x,
      mousePos.y,
      viewportState.xOffset,
      viewportState.yOffset,
      viewportState.scale
    );

    maxIterations *= 1.3;

    window.console.log(
      "Dispatching _render function with " +
        Math.round(Math.pow(Math.log(maxIterations), 3)) +
        " max iterations."
    );
    _render(
      updateProgress,
      false,
      Math.round(Math.pow(Math.log(maxIterations), 3)),
      complexCoords.x,
      complexCoords.y,
      viewportState.scale * zoomLevel,
      viewportState,
      colored
    );
  };

  return cancelRender;
}

interface ViewportState {
  c: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  xOffset: number;
  yOffset: number;
  scale: number;
  rendering: boolean;
  renderId: number;
  cancelledRenders: number[];
  depth: number;
}

interface Point {
  x: number;
  y: number;
}

// assumes a viewport with height = 4/7th of width, with (0,0) at 5/7th width and 1/2 height (assuming no offset).
function convertToComplexPlaneCoords(
  canvas: HTMLCanvasElement,
  pX: number,
  pY: number,
  xOffset: number,
  yOffset: number,
  scale: number
) {
  return {
    x: (pX - (canvas.width / 7) * 5) / scale + xOffset,
    y: (-1 * pY + canvas.height / 2) / scale + yOffset
  };
}

// get the pixel coords of a click on a canvas, regardless of the CSS scaling.
function getMousePos(canvas: HTMLCanvasElement, evt: MouseEvent) {
  const rect = canvas.getBoundingClientRect();

  return {
    x: ((evt.clientX - rect.left) / (rect.right - rect.left)) * canvas.width,
    y: ((evt.clientY - rect.top) / (rect.bottom - rect.top)) * canvas.height
  };

  // // get the scale based on actual width;
  // var sx = canvas.width / canvas.offsetWidth;
  // var sy = canvas.height / canvas.offsetHeight;

  // return {
  //     x: Math.round((evt.pageX - canvas.offsetLeft) * sx), //x: evt.clientX - rect.left,
  //     y: Math.round((evt.pageY - canvas.offsetTop) * sy) //y: evt.clientY - rect.top
  // };
}
