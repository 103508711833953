import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

function LoadPlausibleScript() {
  useEffect(() => {
    const env = process.env.NODE_ENV;

    const jsPath = env === "production" ? "js/main.js" : "js/main.local.js";
    const eventPath = env === "production" ? "api/event" : "api/dev/event";

    const script = document.createElement("script");

    script.dataset.domain = env === "production" ? "distant.land" : "localhost";
    script.dataset.api = `https://plausible.distant.land/${eventPath}`;
    script.src = `https://plausible.distant.land/${jsPath}`;

    script.onload = () => {
      console.log(`loaded plausible script (${env} mode)`);
    };

    document.body.appendChild(script);
  }, []);

  return null;
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    <LoadPlausibleScript />
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
